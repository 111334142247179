// ___________________________________________OVERRIDE____________________________________________

$grid-gutter-width: 24px !default;
// Calculation
$header-height: 82.8px;
// $sidebar-width: 103px;
// $sidebar-width: 143px;
// $sidebar-width: 110px;
$sidebar-width: 96px;
// $sidebar-width: 90px;
$expanded-sidebar-width: 242px;
// New colors
$primary-50: #fde0d8;
$primary-100: #f8a189;
$primary-200: #f68261;
$primary-300: #f4633a;
$primary-400: #c4502e;
$secondary-50: #a5b5d9;
$secondary-100: #6076a7;
$secondary-200: #3e568e;
$secondary-300: #1c3775;
$secondary-400: #162c5e;
$secondary-500: #104061;
$secondary-450: #1c377526;
$success-50: #94d8ac;
$success-100: #69a981;
$success-200: #4d8b63;
$success-300: #144d29;
$success-400: #0c2e19;
$danger-50: #e5adad;
$danger-100: #cc6c6c;
$danger-200: #bc4141;
$danger-300: #ac1616;
$danger-400: #8a1212;
$warning-50: #f8f8b9;
$warning-100: #f1f174;
$warning-200: #ecec45;
$warning-300: #e7e717;
$warning-400: #b9b912;
$info-50: #b9aac9;
$info-100: #9c86b2;
$info-200: #7e639a;
$info-300: #603f83;
$info-400: #4d3269;
$gray-50: #f7f7f7;
$gray-100: #e8e8e7;
$gray-200: #d1d1d0;
$gray-300: #b3b3b0;
$gray-400: #959593;
$gray-500: #b6bfd4;
$gray-descriptor: #989898;
$blue-300: #0e5eda;
// Main colors
$blue: $blue-300;
$primary: $primary-300;
$secondary: $secondary-300;
$success: $success-300;
$danger: $danger-300;
$warning: $warning-300;
$info: $info-300;
$gray: $gray-300;
// Basic colors
$body-background: #f8f9fc;
$black: #101820;
$black-10: #{$black}01;
$black-20: #{$black}02;
$white: #ffffff;

// Old colors
// main
// $primary: #001a37;
// $secondary: #c34000;
// other
$primary-light: #001a3799;
$secondary-light: #ffa149;
$red: #eb5757;
$green: #00ff19;
$success-banner: #00ff1954;
$blue-select: #2f80ed;
$cta: #219653;
$disabled: #c4c4c4;
$disabled-text: #e5e5e5;
$light-grey: rgba(224, 224, 224, 0.5);
// New additions
$red-cancel: rgba(235, 87, 87, 0.1);
$black: #000000;
$dim-black: #0000001a;
$black-50: rgba($black, 0.5);
$black-30: rgba($black, 0.3);

$surface-background: #ffffff;
$white: #ffffff;
$white-50: rgba($white, 0.5);
$hover: #e5e5e542;
$scheduler-table-background: #f0f5ff;

$enable-smooth-scroll: true;
$light-sea-green: #12b99b;
$data-table-border: #e8eaf1;
$no-value-text: #9e9e9e;
$almost-black: #00000026;
$dim-bluish-grey: #e1e6f2;
$dark-orange: #d9390c;
$border-white-grey: #dde2eb;
$badge-purple: #B9AAC9;

// Color maps
// For generating alpha colors, add the color here.
// Utilities will automatically generate alpha colors for that color
// for both foreground and background. Range from 10 to 100 of 10s (eg, 10, 20, 30 ...100)
$color-maps-50: (
    'primary-50': $primary-50,
    'secondary-50': $secondary-50,
    'success-50': $success-50,
    'danger-50': $danger-50,
    'warning-50': $warning-50,
    'info-50': $info-50,
    'gray-50': $gray-50,
);
$color-maps-100: (
    'primary-100': $primary-100,
    'secondary-100': $secondary-100,
    'success-100': $success-100,
    'danger-100': $danger-100,
    'warning-100': $warning-100,
    'info-100': $info-100,
    'gray-100': $gray-100,
);
$color-maps-200: (
    'primary-200': $primary-200,
    'secondary-200': $secondary-200,
    'success-200': $success-200,
    'danger-200': $danger-200,
    'warning-200': $warning-200,
    'info-200': $info-200,
    'gray-200': $gray-200,
);
// main colors map
$color-maps: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'green': $green,
    'danger': $danger,
    'warning': $warning,
    'info': $info,
    'gray': $gray,
    'black': $black,
    'body-background': $body-background,
    // Other
    'blue': $blue,
    'light-grey': $light-grey,
    'primary-light': $primary-light,
    'secondary-light': $secondary-light,
    'red': $red,
    'blue-select': $blue-select,
    'cta': $cta,
    'disabled': $disabled,
    'red-cancel': rgba(235, 87, 87, 0.1),
    'scheduler-table-background': $scheduler-table-background,
    'gray-descriptor': $gray-descriptor,
    'primary-300': $primary-300,
    'light-sea-green': $light-sea-green,
    'badge-purple': $badge-purple,
);
$color-maps-400: (
    'primary-400': $primary-400,
    'secondary-400': $secondary-400,
    'success-400': $success-400,
    'danger-400': $danger-400,
    'warning-400': $warning-400,
    'info-400': $info-400,
    'gray-400': $gray-400,
);

// ___________________________________________OTHER____________________________________________

// Others

// New Vars

// --------------------Old vars
// z-index
$zindex-dropdown: 600;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-app-header: 1040;
$zindex-offcanvas: 1050;
$zindex-modal-backdrop: 1060;
$zindex-popover: 1070;
$zindex-modal: 1075;
$zindex-tooltip: 1080;

// Form vars
$form-footer-height: 78px;

// heights
$dynamic-form-height: calc(80vh - $form-footer-height);

// Shadows
$input-shadow: 0px 4px 2px rgba(0, 0, 0, 0.04), 0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2),
    inset 0px 0.09px 4.18px rgba(97, 97, 97, 0.2);

$shadow-main-sheet: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);

$nav-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);

$form-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);

$search-shadow: 0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2), 0px 1.09009px 2.18018px rgba(97, 97, 97, 0.2),
    inset 0px 0px 2px rgba(0, 0, 0, 0.14);

$dropdown-shadow: 0px 4px 2px rgba(0, 0, 0, 0.04), 0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2),
    inset 0px 0.09px 4.18px rgba(97, 97, 97, 0.2);

// Use in scheduler toggle after meeting with sher
$segment-inner: inset 0px 1px 2px rgba(97, 97, 97, 0.2), inset 0px 2px 4px rgba(97, 97, 97, 0.2);

// borders
$input-border: 1px solid rgba(28, 55, 117, 0.15);
$nav-right-border: 1px solid $disabled-text;
$card-border-color: #e2e7f3;
// dimensions

$input-height: 55px;
$input-border-radius: 150px;
$input-radius: 10px;

// SchedulerModule
$scheduler-height: 82vh;
