@import './components/modal.scss';
@import './components/steps-tab.scss';
@import './components/card.scss';
@import './components/switch.scss';
@import './components/buttons.scss';
@import './components/innova-table-v2.scss';

.selected-button {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    background-color: $primary !important;
    border: none !important;
    color: $white !important;
}

.unselected-button {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    border: 1px solid $black-10 !important;
    color: $secondary !important;
    background-color: $white !important;
    transition: 0.25s ease all 0s;

    &[disabled] {
        &:hover {
            background-color: inherit !important;
            color: #c4c4c4 !important;
        }
    }

    &:hover {
        background-color: $primary-100 !important;
        color: white !important;
    }
}

.selected-tab {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 3px solid $primary !important;
    color: $primary !important;
}

.unselected-tab {
    width: 100%;
    height: 40px;
    white-space: nowrap;
    border: none !important;
    border-bottom: 1px solid #A5B5D9 !important;
    color: $secondary !important;
    background-color: transparent !important;
    transition: 0.25s ease all 0s;
    
    &[disabled] {
        &:hover {
            border: none !important;
            background-color: inherit !important;
        }
    }
    
    &:hover {
        border: none !important;
        background-color: $primary-100 !important;
    }
}

.innova-radio {
    width: 180px;
    height: 55px;
    background-color: white !important;
    border-radius: 10px;
}

input[type='radio'] {
    accent-color: $secondary;
    width: 16px;
    height: 16px;
}

.rounded-curve {
    border-radius: 10px !important;
}

.rounded-modal {
    border-radius: 20px !important;
}

#rounded-modal {
    border-radius: 20px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.character-circle {
    @extend .center;
    // padding-left: 0.5rem !important;
    width: 50px;
    height: 50px;
    font-size: 1.1rem !important;
}

.multiselect-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .badge {
        margin-left: 10px;

        &:first-child {
            margin-left: 0px;
        }
    }
}

.icon-text-item {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // font
    font-family: Quicksand;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    z-index: 1000 !important;
}

// Form controls

.select-field {
    @extend .body-small;
    width: 119px !important;
    height: 44px !important;
    background-color: $body-background !important;
    border: none !important;
}

.form-control {
    // box-shadow: $input-shadow;
    height: 37px !important;
    background: transparent;
    box-sizing: border-box;
    border: none !important;
    height: 55px !important;
    padding: 0.5rem 1rem !important;
    min-width: 60px;
    border-radius: 10px !important;
    outline: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $primary;
    &::placeholder {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        color: $secondary-300 !important;
        opacity: 0.6 !important;
        font-size: 14px;
        line-height: 20px;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.border-search-results {
    border-bottom: 1px solid $black-10;
}

// Tabs
.tab-small {
    @extend .ml-2;
    @extend .rounded;
    width: 96px !important;
    height: 56px !important;

    &:first-child {
        margin-left: 0px !important;
    }
}

// Facility drop down styles
.search-container {
    @extend .input-height;
    border-radius: 150px;
    background-color: $white;

    ::placeholder {
        color: $secondary-50;
        font-size: 1em;
    }

    .input-group-append {
        border: none;
        background-color: transparent;

        .input-group-text {
            @extend .input-group-append;
        }
    }

    // Forms

    .form-control {
        @extend .input-height;
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $primary;

        &:focus {
            box-shadow: none !important;
            outline: none !important;
            background: transparent !important;
        }

        ::placeholder {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.facility-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: $primary-light;
        padding-left: 12px;
    }

    .facility-name {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        color: $primary-light;
        padding: 9px;
        border-radius: 4px;
        border: 0.5px solid $black-30;
    }

    .dropdown-item:active {
        background-color: $primary;
    }

    .facility-dropdown-item {
        border: none;
        padding: 5px;
    }
}

.borderless {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.patient-card {
    @extend .card-border-05;
    border-radius: 16px;
    padding: 16px 20px;

    .show-more {
        cursor: pointer;

        &:hover {
            background-color: $primary-light !important;
        }
    }
}

// Icons
.innova-icon {
    width: 20px;
    height: 20px;
    color: $primary-light;
    cursor: pointer;
}

.big-check {
    width: 30px;
    height: 30px;
}

.checkbox {
    cursor: pointer;
    transform: scale(1.5);
    accent-color: $secondary;
}

// Buttons

.btn {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
}

.innova-btn-layout {
    @extend .btn;
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    border-radius: 100px !important;

    &-sm {
        height: 41px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    &-md {
        height: 51px;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    &-lg {
        height: 61px;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    &-xl {
        height: 71px;
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
}

.innova-btn {
    @extend .btn;
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    border-radius: 100px !important;

    &-sm {
        height: 41px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    &-md {
        height: 51px;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    &-lg {
        height: 61px;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    &-xl {
        height: 71px;
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    &:hover {
        background-color: $body-background !important;
        color: $secondary-200 !important;
        border-color: $secondary-200 !important;
    }

    &:disabled {
        background-color: $disabled !important;
        color: $white !important;
        border-color: $disabled !important;
    }
}

.btn-add-appointment {
    @extend .search-shadow;
    border: 0.25px solid rgba(0, 0, 0, 0.23);
    // box-shadow: 0px 0.545045px 1.09009px rgba(97, 97, 97, 0.2), 0px 1.09009px 2.18018px rgba(97, 97, 97, 0.2);
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 28px !important;
    color: $blue-select !important;
    letter-spacing: 0.15px !important;
}
.light-blue-btn {
    @extend .btn;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    background-color: rgba(165, 181, 217, 0.2) !important;
    padding: 8px 0 !important;
    color: $secondary-300 !important;
}
.toggle-container {
    background-color: $light-grey;
    padding: 4px !important;
    box-shadow:
        inset 0px 1px 2px rgba(97, 97, 97, 0.2),
        inset 0px 2px 4px rgba(97, 97, 97, 0.2);
    border-radius: 4px;

    .nav-link {
        font-family: Roboto;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 18px !important;
        color: #616161 !important;
    }

    .active {
        box-shadow:
            0px 1px 2px rgba(97, 97, 97, 0.2),
            0px 2px 4px rgba(97, 97, 97, 0.2);
        border-radius: 4px;

        color: $black !important;
        background-color: $surface-background !important;
    }
}

textarea.file-description {
    @extend .body3;
    background-color: $disabled-text;
    outline: none;
}

data-list.search-results {
    position: absolute;
    top: 100%;
    box-shadow: $input-shadow;
    outline: $input-border;
    background: #fff;
    display: flex;
    padding: 0;
    flex-direction: column !important;

    option.result {
        cursor: pointer !important;
        padding: 5px;

        &:hover {
            background-color: $primary;
            color: $surface-background !important;
        }
    }
}

.selection {
    .selection-global {
        border-radius: 5px;
    }

    .item {
        @extend .selection-global;
        cursor: pointer;

        &:hover {
            background-color: $disabled-text;
        }
    }

    .selected {
        @extend .selection-global;
        background-color: $disabled !important;
    }
}

// Card search
.card-search {
    & * {
        background-color: transparent;
    }

    background-color: $disabled-text !important;
}

.planner-popup-button {
    color: white !important;
}

.accordion {
    .card {
        .card-header {
            border: none !important;
            padding: 0px !important;

            .btn {
                box-shadow: none !important;
                outline: none !important;
                padding: 0 !important;
                text-transform: none !important;
                text-decoration: none !important;
                width: 100%;

                div {
                    padding: 2px;
                }
            }
        }
    }
}

// Design fixes for components

select {
    appearance: none !important;
    background-image: url('./../../assets/icons/select-chevron.svg') !important;
    background-repeat: no-repeat !important;
    background-position-y: 50% !important;
    padding-right: 24px !important;
    background-position-x: calc(100% - 8px) !important;
    &.form-control {
        padding-right: 24px !important;
    }
}

.result-card {
    //@extend .bg-body-background;

    //border-radius: 4px;
    &:hover {
        cursor: pointer;
        box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 26%) !important;
        transform: translateY(-2px);
    }
}

.card-shadow {
    box-shadow:
        8px 4px 0 1px #eff6ff,
        8px 4px 0 2px rgba(28, 55, 117, 0.15);
}

// convert to utility
.card-border-success-100 {
    border-color: 1px solid $success-100 !important;
}

.light-text {
    opacity: 0.3;
}

.auto-schedule-drop {
    margin-top: 10px;
    height: calc(100vh - $header-height - 100px);
    background-color: #d9d9d9;
    border: 1px dashed #000000;
    border-radius: 10px;
}

// Tooltips
.skilled-services-tooltip {
    background-color: $secondary;
    color: $white;
}

.print-icon {
    cursor: pointer;
    display: flex;
}

.highlight-mark {
    padding: 0px !important;
    margin: 0px !important;
    color: transparent !important;
}
