// ====================
// Based on new Designs
// ====================
.back-btn-title {
    font-size: 1.25rem !important; // 20px
    font-weight: 600 !important;
    line-height: 30px !important;
}
.back-btn-arrow {
    color: $secondary-300;
}

.h6-semi-bold {
    font-family: Poppins;
    font-size: 1.25rem !important; // 20px
    font-weight: 600 !important;
    line-height: 20px !important;
}
.h6-bold {
    font-family: Poppins;
    font-size: 1.25rem !important; //20px
    font-weight: 700 !important;
    line-height: 20px !important;
}
.headline5 {
    font-family: Poppins;
    font-size: 1rem !important; // 16px
    font-weight: 600 !important;
    line-height: 24px !important;
}
.headline5-5 {
    @extend .headline5;
    font-size: 1.125rem !important; // 18px
    line-height: 27px !important;
}

.medium-semi-bold {
    @extend .headline5;
    line-height: 26px !important;
}
.medium-semi-bold4-5 {
    @extend .headline5;
    font-size: 0.9rem !important;
    line-height: 26px !important;
}
.medium-semi-light-bold {
    @extend .headline5;
    font-weight: 500 !important;
}

.body-regular-14 {
    font-family: Poppins;
    font-size: 0.875rem !important; // 14px
    font-weight: 400 !important;
    line-height: 21px !important;
}
.body-regular-light-bold-14 {
    @extend .body-regular-14;
    font-weight: 500 !important;
}
.body-regular-inter-16 {
    // design name: Body 1
    font-size: 1rem !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    line-height: 22px !important;
}
.body-regular-12 {
    font-family: Poppins;
    font-size: 0.75rem !important; // 12px
    font-weight: 400 !important;
    line-height: 18px !important;
}
.body-regular-12-no-lineHeight {
    font-family: Poppins;
    font-size: 0.75rem !important; // 12px
    font-weight: 400 !important;
}
.body-bold-14 {
    @extend .body-regular-14;
    font-weight: 600 !important;
}

.mt-3-5 {
    margin-top: 1.5rem !important;
}
.mt-4-5 {
    margin-top: 1.75rem !important;
}
.mb-3-5 {
    margin-bottom: 1.5rem !important;
}
.mx-4-5 {
    margin: 0 2rem;
}

.standard-btn {
    width: 152px;
    height: 40px;
    border-radius: 56px;
    border: none;
}
// colworx-ak add custom generic styles
.gap-xs {
    gap: 0.5rem;
}
.gap-sm {
    gap: 1rem;
}
.poppins {
    font-family: 'poppins';
}
// colworx-ak end
.height-100-percent {
    height: 100%;
}
.delete-item {
    color: $primary-400;
}
