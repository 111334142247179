.card-border-05 {
  border: 0.5px solid rgba(0, 26, 55, 0.6) !important;
}

.border-bt-rounded {
  border-radius: 0px 0px 10px 10px;
}

.border-rounded {
  border-radius: 10px !important;
}
