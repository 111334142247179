.jsondiffpatch-delta {
    font-family: 'Poppins';
    font-size: 14px;
    margin: 0;
    padding: 0 0 0 12px;
    display: inline-block;
}
.jsondiffpatch-delta pre {
    font-family: 'Poppins';
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-wrap: wrap !important;
}
ul.jsondiffpatch-delta {
    list-style-type: none;
    padding: 0 0 0 20px;
    margin: 0;
}
.jsondiffpatch-delta ul {
    list-style-type: none;
    padding: 0 0 0 20px;
    margin: 0;
}
.jsondiffpatch-added .jsondiffpatch-property-name,
.jsondiffpatch-added .jsondiffpatch-value pre,
.jsondiffpatch-modified .jsondiffpatch-right-value pre,
.jsondiffpatch-textdiff-added {
    background: #bbffbb;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
.jsondiffpatch-deleted .jsondiffpatch-property-name,
.jsondiffpatch-deleted pre,
.jsondiffpatch-modified .jsondiffpatch-left-value pre,
.jsondiffpatch-textdiff-deleted {
    background: #ffbbbb;
    text-decoration: line-through;
}
.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination {
    color: gray;
}
.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination > .jsondiffpatch-value {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    overflow-y: hidden;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-showing .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 100px;
}
.jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 0;
}
.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value,
.jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value {
    display: block;
}
.jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-visible .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 100px;
}
.jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value {
    max-height: 0;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-arrow,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow {
    display: none;
}
.jsondiffpatch-value {
    display: inline-block;
}
.jsondiffpatch-property-name {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
}
.jsondiffpatch-property-name:after {
    content: ': ';
}
.jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name:after {
    content: ': [';
}
.jsondiffpatch-child-node-type-array:after {
    content: '],';
}
div.jsondiffpatch-child-node-type-array:before {
    content: '[';
}
div.jsondiffpatch-child-node-type-array:after {
    content: ']';
}
.jsondiffpatch-child-node-type-object > .jsondiffpatch-property-name:after {
    content: ': {';
}
.jsondiffpatch-child-node-type-object:after {
    content: '},';
}
div.jsondiffpatch-child-node-type-object:before {
    content: '{';
}
div.jsondiffpatch-child-node-type-object:after {
    content: '}';
}
.jsondiffpatch-value pre:after {
    // content: ',';
    content: '';
}
li:last-child > .jsondiffpatch-value pre:after,
.jsondiffpatch-modified > .jsondiffpatch-left-value pre:after {
    content: '';
}
.jsondiffpatch-modified .jsondiffpatch-value {
    display: inline-block;
    margin-right: 5px;
}
.jsondiffpatch-modified .jsondiffpatch-right-value {
    // margin-left: 5px;
}
.jsondiffpatch-moved .jsondiffpatch-value {
    display: none;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination {
    display: inline-block;
    background: #ffffbb;
    color: #888;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination:before {
    content: ' => ';
}
ul.jsondiffpatch-textdiff {
    padding: 0;
}
.jsondiffpatch-textdiff-location {
    color: #bbb;
    display: inline-block;
    min-width: 60px;
}
.jsondiffpatch-textdiff-line {
    display: inline-block;
}
.jsondiffpatch-textdiff-line-number:after {
    content: ',';
}
.jsondiffpatch-error {
    background: red;
    color: white;
    font-weight: bold;
}

.open-history-btn {
    padding: 7px;
    background-color: #1c3775;
    border-radius: 8px 0 0 8px;
    width: 181px;
    position: fixed;
    right: -150px;
    z-index: 1000;
    cursor: pointer;
    transition: 0.5s;
    top: 285px;
    &:hover {
        right: 0;
    }
    span {
        margin-right: 8px;
    }
}
.open-history-btn.hide {
    opacity: 0;
    visibility: hidden;
}
.open-history-btn.show {
    opacity: 1;
    visibility: visible;
}
.open-audit-btn {
    padding: 7px;
    background-color: #1c3775;
    border-radius: 8px 0 0 8px;
    width: 181px;
    position: fixed;
    right: -150px;
    z-index: 1000;
    cursor: pointer;
    transition: 0.5s;
    top: 330px;
    &:hover {
        right: 0;
    }
    span {
        margin-right: 8px;
    }
}
.open-audit-btn.hide {
    opacity: 0;
    visibility: hidden;
}
.open-audit-btn.show {
    opacity: 1;
    visibility: visible;
}

.change-item-text {
    margin: 0px !important;
    line-height: 2;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: $primary-300;
}

.change-item-text-without-line {
    margin: 0px !important;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    color: $primary-300;
}

.change-item-text:hover {
    @extend .underline-item;
}
.underline-item {
    text-decoration: underline;
}