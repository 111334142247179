.planner-single-day-body {
  width: 100%;
  height: 120px;
}
.single-day-table {
  // @extend .w-100;
  // height: 120px;
  width: 100%;
  // border-collapse: separate;
  border-spacing: 0px 5px !important;
  thead {
    th {
      //styleName: quicksand_number_small;
      @extend .fw-500;
      font-family: Quicksand;
      font-size: 12px;
      font-style: normal;
      line-height: 12px;
      letter-spacing: 0.15px;
      text-align: left;
      // Colors
      background-color: transparent !important;
      color: black !important;
    }
  }
  tbody {
    tr {
      color: $secondary;
      td {
        white-space: nowrap !important;
        // background-color: $white;
        @extend .body1;
        @extend .p-1;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

// sections

td.planner-data {
  border: 2px solid transparent;
}
// Planner missed sections
.missed-section {
  border: 2px solid $red !important;
  border-left: none !important;
  border-right: none !important;
}
.missed-section-start {
  border: 2px solid $red !important;
  border-right: none !important;
}
.missed-section-end {
  border: 2px solid $red !important;
  border-left: none !important;
}
// Planner done sections
// .done-section {
//   border: 2px solid $green !important;
//   border-left: none !important;
//   border-right: none !important;
// }
// .done-section-start {
//   border: 2px solid $green !important;
//   border-right: none !important;
// }
// .done-section-end {
//   border: 2px solid $green !important;
//   border-left: none !important;
// }
#dd-toggle::after {
  display: none;
}
.planner-popup {
  position: absolute;
  margin-left: 150px;
  margin-top: -90px;
  z-index: auto !important;
}
.minutes-planner {
  width: 211px;
  height: 438px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.minutes-planner-editor {
  width: 211px;
  height: 290px;
  background-color: white;
  overflow-y: auto;
}
