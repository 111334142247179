@import './variables.scss';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vfftko');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?vfftko#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vfftko') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vfftko') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vfftko##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-billing {
  &:before {
    content: $icon-billing;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-first-aid {
  &:before {
    content: $icon-first-aid;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-notepad {
  &:before {
    content: $icon-notepad;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
