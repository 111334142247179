@for $i from 1 through 20 {
  .dot-#{$i} {
    display: inline-block;
    width: #{$i}px;
    height: #{$i}px;
    border-radius: 100%;
  }
}

// Creating dot badge
.dot-badge {
  &-before-transparent {
    &::before {
      content: '';
      @extend .dot-8;
      background-color: transparent;
    }
  }
  &-before {
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $badge-purple {
      &-#{$color-name} {
        &::before {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
  }
  &-after-transparent {
    &::after {
      content: '';
      @extend .dot-8;
      background-color: transparent;
    }
  }
  &-after {
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::after {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::after {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::after {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::after {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::after {
          content: '';
          @extend .dot-8;
          background-color: $color-value;
        }
      }
    }
  }
}

// Drag badge
.drag-badge {
  &-before {
    &-transparent {
      &::before {
        content: '\e945';
        font-family: 'Material Icons';
        color: transparent;
      }
    }
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::before {
          content: '\e945';
          font-family: 'Material Icons';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::before {
          content: '\e945';
          font-family: 'Material Icons';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::before {
          content: '\e945';
          font-family: 'Material Icons';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::before {
          content: '\e945';
          font-family: 'Material Icons';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::before {
          content: '\e945';
          font-family: 'Material Icons';
          color: $color-value;
        }
      }
    }
  }
}

// Discipline badges
.pt-badge {
  &-before-transparent {
    &::before {
      content: 'PT';
      background-color: transparent;
    }
  }
  &-before {
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::before {
          content: 'PT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::before {
          content: 'PT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::before {
          content: 'PT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::before {
          content: 'PT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::before {
          content: 'PT';
          color: $color-value;
        }
      }
    }
  }
}
.ot-badge {
  &-before-transparent {
    &::before {
      content: 'OT';
      background-color: transparent;
    }
  }
  &-before {
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::before {
          content: 'OT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::before {
          content: 'OT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::before {
          content: 'OT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::before {
          content: 'OT';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::before {
          content: 'OT';
          color: $color-value;
        }
      }
    }
  }
}
.st-badge {
  &-before-transparent {
    &::before {
      content: 'ST';
      background-color: transparent;
    }
  }
  &-before {
    @each $color-name, $color-value in $color-maps-50 {
      &-#{$color-name} {
        &::before {
          content: 'ST';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-100 {
      &-#{$color-name} {
        &::before {
          content: 'ST';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-200 {
      &-#{$color-name} {
        &::before {
          content: 'ST';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps {
      &-#{$color-name} {
        &::before {
          content: 'ST';
          color: $color-value;
        }
      }
    }
    @each $color-name, $color-value in $color-maps-400 {
      &-#{$color-name} {
        &::before {
          content: 'ST';
          color: $color-value;
        }
      }
    }
  }
}
