.btn-circle {
  border-radius: 50% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.5em !important;
}

.btn {
    &:disabled {
        background-color: #D1D1D0 !important;
        opacity: 1 !important;
        border: none !important;
    }   
}

.innova-btn.btn-outline {
  color: $secondary-300;
  border-color: $secondary-300;
  background-color: transparent !important;
  &:disabled {
    background-color: transparent !important;
    border: 1px solid $gray-200 !important;
    color: $gray-200 !important;
  }
}