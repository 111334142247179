@import 'material-icons/iconfont/material-icons.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import './assets/icons/style.scss';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'src/assets/styles/app-style.scss';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

// Ag-Grid

.ag-theme-alpine {
    width: 100%;
    height: 100%;
    @include ag-theme-alpine(
        (
            font-family: Poppins,
            // odd-row-background-color: #f8f9fc,
            header-background-color: #f8f9fc,
            foreground-color: $secondary-300,
            width: 100%,
            height: 100%,
        )
    );
    // --ag-borders: none;
    // --ag-row-border-style: dashed;
    // --ag-row-border-width: 5px;
    // --ag-row-border-color: rgb(150, 150, 200);
    .ag-root-wrapper.ag-layout-normal {
        height: 100%;
    }
    .ag-root-wrapper {
        border: none;
        // border-bottom: 1px solid rgba(28, 55, 117, 0.15);
        border-radius: 0 0 15px 15px; /*to remove border radius for treatment in daily-notes */
    }
    .ag-header {
        border-bottom-color: rgba(28, 55, 117, 0.15);
    }
    .ag-row {
        border-bottom-style: none;
        + .ag-row {
            border-top: 1px solid rgba(28, 55, 117, 0.15);
        }
    }
    .ag-paging-panel {
        background-color: #f8f9fc;
        border-top: 1px solid rgba(28, 55, 117, 0.15) !important;
        border-bottom: 1px solid rgba(28, 55, 117, 0.15) !important;
        border-radius: 0 0 15px 15px;
    }
    .ag-header-cell {
        &.primary-dx-required {
            color: red;
        }
    }
    .ag-header-cell.bg-white {
        background-color: $white;
    }
}

.hide-ag-checkbox .ag-selection-checkbox {
    display: none !important;
}

html,
body {
    overflow-x: hidden;
    overflow-y: hidden;
}

// Custom css
.container {
    background-color: $body-background;
}
.bg-light {
    background-color: $surface-background !important;
}

// Css for scrollbar
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar:horizontal {
    height: 5px;
}
::-webkit-scrollbar:hover {
    width: 5px;
}

.webkit-sm {
    ::-webkit-scrollbar {
        width: 3px !important;
    }
}
/* Track */
::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: #888;
    // background: $primary-light;
    background-color: #b1b9cb;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #555;
    background: $primary-200;
}

// Remove style below after checking

// @import './scss/typography.scss';
// global styles
a {
    cursor: pointer;
}

.app-container {
    min-height: 320px;
    // .bg-light {
    //   background-color: $surface-background !important;
    // }
}

.btn-delete-account {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}
.login-page-wrapper {
    margin: -30px -30px 0;
    .login-page-wrapper-inner {
        height: 100vh;
    }
    img.login-logo {
        width: 50%;
    }
    .login-bottom-img {
        bottom: 0;
        left: 0;
        z-index: 1 !important;
        width: 100%;
        img {
            width: 10%;
        }
    }
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.planner-datepicker {
    ngb-datepicker.dropdown-menu.show {
        margin-left: -210px !important;
        margin-top: 38px !important;
    }
}

.cdk-drag-placeholder {
    opacity: 0;
}

#print-content-cont {
    display: none;
}

#header-bg-grid .ag-header-cell,
#header-bg-grid .ag-header-viewport {
    background-color: #e2e7f3 !important;
    color: #1c3775 !important;
}

@media print {
    @page {
        size: auto;
    }
    app-root > * {
        display: none;
    }
    app-left-nav-bar {
        display: none;
    }
    app-scheduler-v2-layout {
        display: none;
    }
    app-therapist-caseload {
        display: none;
    }
    app-white-board-layout {
        display: none;
    }
    app-pcc-layout {
        display: none;
    }
    app-therapy-case-layout {
        display: none;
    }
    app-print-layout {
        display: block !important;
    }
    app-therapist-case-load {
        display: block !important;
    }
    app-dor-view {
        display: block !important;
    }
    #patient-header-box {
        display: none !important;
    }
    body {
        background-color: white !important;
        overflow: visible !important;
    }
    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
        print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
    }
    .main-container {
        .lh-table {
            .print-width {
                width: 200px !important;
            }
            .print-width-two {
                width: 200px !important;
                left: 200px !important;
            }
            .print-width-three {
                width: 200px !important;
                left: 400px !important;
            }
            .print-width-four {
                width: 200px !important;
                left: 600px !important;
            }
            .print-width-five {
                width: 250px !important;
                left: 800px !important;
            }
        }
    }
    #print-content-cont {
        display: block;
    }
}

.custom-slider .ngx-slider .ngx-slider-selection {
    background: $secondary-300 !important;
}
.custom-slider .ngx-slider .ngx-slider-tick {
    background: $secondary-300 !important;
    height: 8px !important;
    width: 8px !important;
    top: 1px !important;
}
.ngx-slider .ngx-slider-tick-legend {
    overflow-wrap: normal !important;
    max-width: 60px !important;
    top: 16px !important;
}
.ngx-slider .ngx-slider-pointer {
    background-color: $secondary-300 !important;
    height: 30px !important;
    width: 30px !important;
    &::after {
        width: 6px !important;
        height: 6px !important;
    }
    &.ngx-slider-active::after {
        background-color: white !important;
    }
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
    justify-content: center;
    align-items: center;
}

.animate {
    transition: 350ms ease-in-out all 0s;
}
.split-pill {
    border-radius: 100px;
    background: #e8edf9;
    color: var(--Primary-Blue-200, #1c3775);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
}
.combined-inputs {
    .form-group {
        margin: 0;
        select {
            border: none !important;
        }
    }
}

.no-select-margin {
    .form-group {
        margin-bottom: 0;
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked:after {
    color: $secondary;
}

.word-break-all {
    word-break: break-all;
}

.custom-card {
    border-radius: 15px;
    border: 1px solid #1c377526;
    // overflow: hidden; // for border radius of last child
    background-color: $white;
    &.card-padding {
        padding: 24px;
    }
    .header {
        background-color: #e2e7f3;
        padding: 12px 12px 12px 18px;
        border-radius: 12px 12px 0 0;
        p {
            font-size: 20px;
            font-weight: 600;
            font-family: 'poppins';
            color: $secondary;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .btn-circle {
            padding: 0 !important;
            width: 32px;
            height: 32px;
            z-index: 10;
        }
    }
    .body {
        &.card-padding {
            padding: 24px;
        }
    }
}

.card-padding-x {
    padding: 0 15px;
}

.card-padding-y {
    padding: 24px 0;
}

select.form-control,
.custom-dropdown {
    border: 1px solid rgba(28, 55, 117, 0.15) !important;
    background-color: $body-background;
    &.sm {
        height: 34px !important;
        font-size: 12px;
        line-height: 14px;
        padding-left: 9px !important;
        border-radius: 4px !important;
        > span {
            line-height: 14px !important;
            padding: 5px !important;
        }
    }
}

// .dropdown {
//     z-index: 999 !important;
// }

.modal-content {
    max-width: 950px;
    width: 90vw;
    &.modal-small {
        width: 400px;
    }
    &.modal-time {
        width: 500px;
    }
    &.modal-medium {
        width: 600px;
    }
    &.modal-semi-medium {
        width: 700px;
    }
    &.modal-xl {
        max-width: 1260px;
    }
    &.manage-labor-modal {
        width: 95vw;
        max-width: 1160px;
    }
}

.input-cell-renderer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.bar-card {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    justify-content: flex-start;
    border-radius: 10px;
}
.pointer-events-none {
    pointer-events: none;
}
.header-center {
    .ag-header-cell-label {
        justify-content: center;
    }
}

.font-14 {
    font-size: 14px;
    line-height: 17px;
}

.font-16 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.font-12 {
    font-size: 12px !important;
    line-height: 15px !important;
}

.font-10 {
    font-size: 10px !important;
    line-height: 13px !important;
}

.font-25 {
    font-size: 25px;
    line-height: 36px;
}

// Screen height
.screen-height {
    height: calc(100vh - 440px);
}

.ngb-tp {
    border: 1px solid #e2e7f3;
    background-color: #f8f9fc;
    border-radius: 8px;
    display: inline-flex !important;
    .ngb-tp-hour,
    .ngb-tp-meridian,
    .ngb-tp-minute,
    .ngb-tp-second {
        width: 36px;
    }
    .ngb-tp-input {
        background-color: transparent;
        padding: 3px !important;
        height: auto !important;
    }
    .ngb-tp-meridian {
        button {
            border: none;
            color: $secondary-300;
            padding: 5px;
            &:hover {
                color: $white;
            }
        }
    }
}
#appointment-time {
    .ngb-tp {
        width: 115px;
    }
}
.addendumBox {
    border-bottom: 1px solid #1c377526;
}
// Scheduler > manage labor > track hours
.datePicker-wrapper {
    border: 1px solid $secondary-400;
    border-radius: 10px;
    background-color: $white;
    margin-right: 10px;
    .ngb-tp-input-container {
        width: 3rem;
    }
    input.form-control {
        height: 29px !important;
        padding: 0 10px !important;
        background-color: transparent;
    }
}
// For Filled group icon in scheduler week-view and day-view
.material-symbols-outlined.filled-icon {
    font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 48;
}
.color-secondary-200 {
    color: $secondary-200;
}
.color-secondary-300 {
    color: $secondary-300;
}
// Border-radius for he last child in role
.accordion > .card:last-child {
    border-radius: 0 0 15px 15px;
}
.accordion {
    .card-header {
        background-color: rgba(226, 231, 243, 0.7);
    }
}
.alignCenter {
    .ag-header-cell-label {
        justify-content: center;
    }
}

.therapist-accordion .card .card-header {
    background-color: inherit !important;
    border: 1px solid var(--primary-blue-40, #e2e7f3) !important;
    border-radius: 8px;
    overflow: hidden;
}
.therapist-accordion .card .card-header#custom-panel-sessions-header:has(.main-row.collapsed) {
    border: 1px solid var(--primary-blue-40, #e2e7f3) !important;
    border-radius: 8px;
}
.therapist-accordion .card .card-header#custom-panel-sessions-header:has(.main-row) {
    border-radius: 8px 8px 0 0;
}
.nested-card,
.activities-wrapper {
    .card {
        overflow: visible;
        .card-header {
            background-color: inherit !important;
            border: 1px solid var(--primary-blue-40, #e2e7f3) !important;
            border-radius: 8px;
            overflow: visible;
        }
    }
}
.nested-box-outer {
    .nested-box .card {
        width: 90%;
        margin-left: auto;
        position: relative;
        border: 1px solid #e2e7f3 !important;
        border-radius: 10px;
        overflow: visible;
        .card-header {
            background-color: transparent;
        }
        &:before {
            position: absolute;
            content: '';
            background-color: $gray-500;
            width: 50px;
            height: 1px;
            left: -50px;
            top: 48%;
        }
        &::after {
            position: absolute;
            content: '';
            z-index: 1;
            width: 1px;
            height: 75px;
            background-color: $gray-500;
            left: -50px;
            top: -44px;
        }
    }
    .nested-box .card:first-child {
        &:before {
            position: absolute;
            content: '';
            background-color: #b6bfd4;
            width: 50px;
            height: 1px;
            left: -50px;
            top: 48%;
        }
        &::after {
            position: absolute;
            content: '';
            z-index: 1;
            width: 1px;
            height: 42px;
            background-color: #b6bfd4;
            left: -50px;
            top: -11px;
        }
    }
}

.therapist-accordion > .card {
    &:last-child {
        border-radius: inherit !important;
    }
}

.contract-accordion {
    border-radius: 15px;
    .card {
        border: 1px solid $card-border-color !important;
        border-radius: 15px !important;
        .card-header {
            border: none !important;
            border-bottom: 1px solid $card-border-color !important;
            background-color: inherit !important;
            .main-row {
                &:not(.collapsed) {
                    border-bottom: 1px solid $card-border-color !important;
                }
            }
        }
    }
}
.contract-search-box {
    width: 400px;
}
.add-contract-section {
    border: 1px solid $card-border-color !important;
    border-radius: 15px !important;
}
.configure-individually {
    font-size: 14px;
    font-weight: 600;
}

.choose-template {
    font-weight: 500;
}

.contract-required {
    font-size: 14px;
    font-weight: 600;
    float: right;
}
.check-mark,
.cross-mark,
.caution-mark {
    width: 17px;
    height: 17px;
    margin: 12px auto 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        font-size: 12px;
        line-height: 12px;
    }
}
#split-min-slider {
    .ngx-slider {
        .ngx-slider-bubble {
            bottom: -25px;
        }
        .ngx-slider-bar-wrapper {
            padding-top: 0;
            height: 8px;
        }
        .ngx-slider-pointer {
            top: -25px;
        }
        .ngx-slider-floor {
            opacity: 0 !important;
            visibility: hidden !important;
        }
        .ngx-slider-ceil {
            opacity: 0 !important;
            visibility: hidden !important;
        }
        .ngx-slider-bar {
            border-radius: 30px;
            background: var(--Primary-Color-100, #f8a189);
            height: 8px;
        }
        .ngx-slider-bar {
            height: 8px;
            border-radius: 30px;
        }
        // .ngx-slider-selection-bar{
        //     visibility: visible !important;
        // }
        .ngx-slider-selection {
            background: var(--Primary-Blue-100, #a5b5d9);
        }
        .ngx-slider-pointer {
            background-color: #d1d1d0 !important;
            height: 24px !important;
            width: 24px !important;
            border: 2px solid #fff;
            &::after {
                display: none;
            }
        }
        .ngx-slider-pointer.ngx-slider-active {
            outline: none;
        }
        .ngx-slider-model-value {
            opacity: 0 !important;
        }
    }
}

.bgGoalColor {
    background-color: #f9fafb;
}
.facilityListDropdown {
    .facility-list-holder {
        max-width: 70px;
        min-width: 70px;
        left: -14px !important;
        .facility-list-dropdown {
            ul {
                li {
                    padding: 0.25rem 1rem !important;
                }
            }
        }
    }
}
.min-height100 {
    min-height: 100px;
}
#facility-dd .list-dropdown ul > li {
    display: flex;
    input.checkbox {
        margin-top: -2px;
    }
}
// for teleHealth Switch button
#telehealthCheck {
    width: 45px;
    .label-outer {
        width: 50px;
    }
    .switch {
        height: 20px !important;
        .slider:before {
            height: 15px !important;
            width: 15px !important;
            bottom: 3px !important;
        }
    }
}
// calendar weekView min width
#scheduler-datepicker-wrapper {
    .wv-date-holder {
        justify-content: center;
        min-width: 200px;
    }
}
.labor-management-table {
    .ag-theme-alpine {
        .ag-header {
            border: none;
        }
    }
}
// Modal Background z-index
.cdk-overlay-container {
    position: fixed;
    z-index: 1050;
}
div.overRight-css.disabled {
    background-color: transparent !important;
    color: initial !important;
    div.bg-transparent {
        display: none;
    }
}
#certification-date-box {
    .wv-date-holder {
        margin-bottom: 0;
    }
}
// Manage labor search field
#manage-labor-search {
    img {
        width: 15px;
    }
}
// ag grid sorting priority count hide
.ag-header-label-icon.ag-sort-order {
    display: none;
}
// #unassign-patient {
//     position: relative;
//     z-index: 1000;
//     &:before {
//         position: absolute;
//         width: 100vw;
//         height: 100vh;
//         content: '';
//         opacity: 0.35 !important;
//         background: black;
//         left: -110%;
//         top: -126%;
//     }
// }
// add cpt modal scrollbar
#add-cpt-modal-grid {
    .ag-root.ag-unselectable.ag-layout-normal ::-webkit-scrollbar {
        display: inherit;
    }
    .ag-theme-alpine .ag-root-wrapper {
        border-radius: 0;
    }
}
#cpt-code-table {
    .ag-theme-alpine .ag-root-wrapper {
        border-radius: 0;
    }
}
// @media screen and (max-width: 1600px) {
//     #unassign-patient {
//         &:before {
//             left: -78%;
//             top: -86%;
//         }
//     }
// }
#planned-unplanned-btn {
    button:disabled,
    button[disabled] {
        color: #c4c4c4 !important;
        background-color: #f0f0f0 !important;
        pointer-events: none;
    }
}

#goal-textarea {
    .form-control {
        &:disabled {
            background-color: #f0f0f0 !important;
        }
    }
}
.input-filed-container {
    position: relative;
    #error-msg-box.input-error {
        position: absolute;
        right: 0;
        top: -25px;
    }
}
// Disable scroll on re-sign doc
#disable-scroll {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
}
// PDPM discipline Styling
#pdpm-discipline {
    .option {
        color: #495057 !important;
        opacity: 1 !important;
    }
}
.co-sign-btn-holder {
    position: fixed;
    left: $sidebar-width;
    right: 10px;
    top: auto;
    bottom: 0;
    padding-bottom: 12px;
    padding: 6px 40px 12px 30px;
    z-index: 999;
}
// change the radio btn background
#student-field {
    div.bg-white {
        background-color: #f8f9fc !important;
    }
}
#add-therapist-availability-modal {
    .add-therapist-availability.modal-content {
        max-width: 550px;
    }
}
#availability-accordion.accordion {
    // .card-body{
    //     height: 575px;
    //     overflow: scroll;
    // }
    .card {
        .card-header {
            border-radius: 12px;
            background-color: transparent;
            .btn {
                border: none;
            }
        }
    }
}
#availability-card {
    .card-padding {
        border-bottom: 1px solid rgba(28, 55, 117, 0.15);
        height: 270px;
        overflow: scroll;
        &:last-child {
            border-bottom: none;
        }
    }
    .card-padding.disable {
        background-color: rgba(226, 231, 243, 0.3);
        pointer-events: none;
    }
}
.no-margin {
    margin: 0 !important;
}
.no-padding {
    padding: 0 !important;
}
.abs-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-min-width-153 {
    min-width: 153px;
}
.rounded-pill-fixed-w {
    width: 90px;
    font-weight: 400;
    font-family: Poppins;
    padding: 0.4rem 0 !important;
}
.custom-card.frequency-cf-ct {
    border: none;
}
.show-mobile {
    display: none !important;
}

// ====================
// Data Grid
// ====================
.ag-history-row-class {
    background-color: $body-background !important;
}
.ag-history-row-class.bg-white {
    background-color: $white !important;
}
.ag-history-row-class.disabled {
    background: #e2e7f3 !important;
}
.ag-row-expand {
    height: 72px;
    transform: translateY(72);
}
#admission-history-cont {
    .ag-header-row-column {
        background-color: $body-background;
        .ag-header-cell-resize {
            display: none;
        }
    }
    .ag-theme-alpine .ag-row {
        text-align: center;
    }
    .ag-header-cell-label {
        justify-content: center;
    }
    .ag-header-row {
        border-top: 1px solid $border-white-grey;
    }
    .icon-lg-cell .material-symbols-outlined {
        font-size: 1.5rem;
    }
    .icon-sm-cell .material-symbols-outlined {
        font-size: 1.425rem;
        font-weight: 800;
    }
    .ag-center-cols-container,
    .ag-center-cols-clipper {
        height: 100% !important;
    }
}

#date-picker-wrapper {
    .dropdown-menu.show {
        z-index: 601;
    }
}
.custom-switch.ml-4 {
    margin-left: 0 !important;
}
.user-detail-header {
    .ag-header-cell-label {
        justify-content: center;
    }
}
.user-detail-cell {
    text-align: center;
    span.disabled {
        background-color: #e8e8e7 !important;
        color: #5a5a58 !important;
    }
}
#payor-auth-bar-card {
    .remove-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
#facility-search-for-recipient {
    .search-container {
        height: 45px !important;
    }
}
#unassign-patient {
    .modal-content {
        .modal-header {
            padding-left: 32px;
        }
    }
}

// ====================
// Dropdown new minor style
// ====================
.pcc-container,
#pcc-therapy-case-header {
    .dropdown-menu {
        min-width: 196px;
        box-shadow: $almost-black 0px 5px 20px 0px;
        border-radius: 0.85rem;
        border: none;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: $dim-bluish-grey;
    }
    .dropdown-list-cont {
        top: 7px !important;
    }
}

// ====================
// PCC v2
// ====================
.patient-white-box {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    .rounded-circle {
        width: 80px;
        height: 80px;
        margin-bottom: 1.25rem;
        span + span {
            margin-left: 1px;
        }
    }
}
#tab-nav-cont {
    .tabs-container {
        border: none !important;
        border-radius: 15px 15px 0 0;
        padding: 0;
        background: rgba(248, 249, 252, 1);
    }
    .tabs-container > div#tabs button {
        border-radius: 0;
    }
    .tabs-container > div#tabs button.unselected-button {
        position: relative;
        // &:before{
        //     position: absolute;
        //     content: "";
        //     right: 0;
        //     top: 10px;
        //     width: 1px;
        //     bottom: 10px;
        //     background-color: #E2E7F3;
        // }
        color: $secondary-300 !important;
        border: none !important;
        background-color: transparent !important;
        border-bottom: 1px solid rgba(226, 231, 243, 1) !important;
        border-right: 1px solid rgba(226, 231, 243, 1) !important;
    }
    .tabs-container > div#tabs div:last-child button.unselected-button:last-child {
        border-right: none;
    }
    .tabs-container > div#tabs button.unselected-button {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 1px;
            bottom: 0;
            background-color: #e2e7f3;
        }
        &::after {
            position: absolute;
            content: '';
            left: -1px;
            top: 0;
            width: 1px;
            bottom: 0;
            background-color: #e2e7f3;
        }
    }
    .tabs-container > div#tabs button.selected-button {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 1px;
            bottom: 0;
            background-color: #e2e7f3;
        }
        &::after {
            position: absolute;
            content: '';
            left: -1px;
            top: 0;
            width: 1px;
            bottom: 0;
            background-color: #e2e7f3;
        }
        border: none !important;
        background-color: #fff !important;
        color: $primary-300 !important;
        border-bottom: 1px solid transparent !important;
    }
    .tabs-container > div#tabs div:first-child button.selected-button::after {
        display: none;
    }
    .tabs-container > div#tabs div:last-child button.selected-button::before {
        display: none;
    }
    .tabs-container > div#tabs div.ml-2 {
        margin-left: 0 !important;
    }
    .tabs-container button:disabled > .nav-button-label {
        opacity: 0.4;
    }
}

#tab-nav-contMobile {
    .tabs-container {
        border: none !important;
        border-radius: 15px 15px 0 0;
        padding: 0;
        background: rgba(248, 249, 252, 1);
    }
    .tabs-container > div#tabs button {
        border-radius: 0;
    }
    .tabs-container > div#tabs button.unselected-button {
        position: relative;
        // &:before{
        //     position: absolute;
        //     content: "";
        //     right: 0;
        //     top: 10px;
        //     width: 1px;
        //     bottom: 10px;
        //     background-color: #E2E7F3;
        // }
        color: $secondary-300 !important;
        border: none !important;
        background-color: transparent !important;
        border-bottom: 1px solid rgba(226, 231, 243, 1) !important;
    }
    .tabs-container > div#tabs div:last-child button.unselected-button::before {
        display: none;
    }
    .tabs-container > div#tabs button.selected-button {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 1px;
            bottom: 0;
            // background-color: #e2e7f3;
        }
        &::after {
            position: absolute;
            content: '';
            left: -1px;
            top: 0;
            width: 1px;
            bottom: 0;
            // background-color: #e2e7f3;
        }
        border: none !important;
        background-color: transparent !important;
        color: $primary-300 !important;
        border-bottom: 1px solid transparent !important;
    }
    .tabs-container > div#tabs div:first-child button.selected-button::after {
        display: none;
    }
    .tabs-container > div#tabs div:last-child button.selected-button::before {
        display: none;
    }
    .tabs-container > div#tabs div.ml-2 {
        margin-left: 0 !important;
    }
    .tabs-container button:disabled > .nav-button-label {
        opacity: 0.4;
    }
}
.pending-doc-icon-wrapper {
    width: 64px;
    height: 66px;
    border: 1px solid var(--Error-100, #e5adad);
    background: var(--Error-50, #f5e8e9);
}
.upcoming-doc-icon-holder {
    width: 64px;
    height: 66px;
    background-color: #edf2ff;
    border: 1px solid rgba(28, 55, 117, 0.2);
}
.pending-cosign-icon-wrapper {
    width: 64px;
    height: 66px;
    border: 1px solid rgba(244, 99, 58, 0.2);
    background-color: #fde0d8;
    .count-box {
        width: 115px;
    }
}
.icon-holder-box {
    transition: all ease-in-out 0.2s;
    .hover-icon {
        display: none;
    }
    &:hover {
        .normal-icon {
            display: none;
        }
        .hover-icon {
            display: block;
        }
    }
}
.text-warning-500 {
    color: var(--warning-500, #e1aa00);
}
.coming-soon-box-wrapper {
    height: 300px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-cell-renderer-wrapper {
    .custom-cell-icon-font {
        font-size: 17px;
    }
    #visit-status-completed {
        width: 21px !important;
        display: inline-flex;
        height: 21px !important;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: var(--success-50, #bbe3c9);
        border-radius: 50%;
        span {
            font-size: 15px;
            color: #4d8b63;
        }
    }
}
#discharge-note-updated {
    .vl {
        border-right: none !important;
    }
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

ngb-timepicker {
    .ngb-tp-input-container {
        .form-control {
            color: $secondary-300;
        }
    }
    &.read-only {
        .ngb-tp {
            border: none;
            background-color: transparent;
            .ngb-tp-input-container {
                color: $secondary-50;
                .form-control {
                    min-width: 50px !important;
                }
            }
            .ngb-tp-spacer {
                color: $secondary-300;
            }
        }
        .ngb-tp-meridian {
            > button {
                background-color: transparent !important;
                // color: $secondary-50;
                &:hover {
                    color: $secondary-300;
                }
            }
        }
    }
}
@media (max-width: 1600px) {
    .pending-doc-icon-wrapper {
        width: 54px;
        height: 56px;
    }
    .upcoming-doc-icon-holder {
        width: 54px;
        height: 56px;
    }
    .pending-cosign-icon-wrapper {
        width: 54px;
        height: 56px;
    }
    .dor-pending-doc-inner {
        padding: 15px 10px !important;
    }
}

.width-25 {
    width: 25%;
}

.width-50 {
    width: 50%;
}

.div-based-table {
    border: 1px solid $secondary-450;
    border-radius: 16px;
    overflow: hidden;
    .thead,
    .tbody {
        display: flex;
        color: $secondary-300;
        font-family: 'Poppins';
        .tr {
            display: flex;
            flex-grow: 1;
            + .tr {
                border-top: 1px solid $secondary-450;
            }
        }
        .th,
        .td {
            padding: 12px 16px;
            align-items: center;
            height: 100%;
            display: flex;
            position: relative;
            + .th {
                border-left: 1px solid $secondary-450;
            }
            + .td {
                border-left: 1px solid $secondary-450;
            }
            .symbol {
                position: absolute;
                top: 50%;
                right: 10px;
                margin: -12px 0 0;
                cursor: pointer;
                z-index: 9;
            }
        }
    }
    .thead {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        background-color: $body-background;
    }
    .tbody {
        flex-direction: column;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
    }
    app-dynamic-range-of-motion-field {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
    }
}

.mmt-holder {
    .mmt {
        background-color: #f8f9fc;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid rgba(28, 55, 117, 0.15);
    }
}

.note-holder {
    .add-note {
        cursor: pointer;
    }
    textarea {
        border: 1px solid #b6bfd4;
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
        &:focus,
        &:focus-visible {
            border-color: #6076a7;
            outline: none;
        }
    }
    .symbol {
        background-color: rgba(172, 22, 22, 0.1);
        width: 24px;
        height: 24px;
        border: 1px solid rgba(172, 22, 22, 0.2);
        color: rgba(172, 22, 22, 1);
        cursor: pointer;
        border-radius: 100%;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 14px;
    }
}

#impairments-toggle {
    .tabs-container {
        border: none;
    }
    #tabs {
        width: auto !important;
    }
}

.resize-none {
    resize: none;
}

.ag-header-dark .ag-header-row {
    background-color: #e2e7f3 !important;
}
.ag-header-cell-center {
    .ag-header-cell-label {
        justify-content: center;
    }
}
.custom-roles-grid-header {
    .ag-header-cell-label {
        justify-content: flex-start;
        padding-left: 13px;
    }
}
.custom-roles-actions-cell {
    > app-actions-cell-renderer {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
// ============================
// ngb Tooltip
// ============================
.tooltip > .tooltip-inner {
    color: white;
    background-color: $secondary-400 !important;
}
.tooltip.ngb-tooltip-custom-cont {
    .tooltip-inner {
        border-radius: 4px;
        border: 1px solid var(--Gray-200, #d1d1d0);
        background-color: var(--White, #fff) !important;
        box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.1);
        color: #162c5e !important;
        max-width: 286px !important;
        text-align: left;
        span {
            font-size: 12px !important;
            font-family: Inter !important;
            line-height: 21px !important;
            font-weight: 600 !important;
        }
    }
    .arrow {
        display: none !important;
    }
}
.ngb-tooltip-custom {
    .tooltip-inner {
        max-width: 250px !important;
        width: 250px !important;
    }
}
.ngb-therapist-caseload-comment {
    .tooltip-inner {
        max-width: 650px !important;
        width: fit-content !important;
    }
}
// end
// ============================

#create-goal-outer-wrapper {
    #level-of-function {
        tr:nth-child(2) {
            td:nth-child(1) {
                width: 20%;
            }
            td:nth-child(2) {
                width: 40%;
            }
            td:nth-child(3) {
                width: 40%;
            }
        }
    }
}
// Dropdown custom styling
.dd-small.dropdown-menu {
    width: 180px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(6.795704364776611px);
    border: none;
    .dropdown-item {
        color: var(--Secondary-one-300, #1c3775);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .dropdown-item.disabled {
        color: #adb5bd;
        pointer-events: none;
        background-color: transparent;
    }
}
.nested-dd.dropdown-menu {
    width: 120px;
    min-width: 120px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(6.795704364776611px);
    border: none;
    right: 0;
    left: auto !important;
    .dropdown-item {
        color: var(--Secondary-one-300, #1c3775);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: block;
        width: 100%;
    }
    .dropdown-item.disabled {
        color: #adb5bd;
        pointer-events: none;
        background-color: transparent;
    }
}
.edit-workflow,
.edit-timeBlock {
    .dd-small.dropdown-menu {
        left: auto !important;
        right: 20px;
        top: auto !important;
        bottom: 0;
        .dropdown-item {
            font-size: 12px;
            display: flex;
            align-items: center;
            span.material-icons-outlined,
            span.material-symbols-outlined {
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
    #dd-toggle {
        &:hover {
            border: 1px solid transparent !important;
            outline: none;
        }
        &:focus {
            border: none !important;
            outline: none;
            box-shadow: none;
        }
    }
}
.edit-timeBlock {
    .dd-small.dropdown-menu {
        bottom: -25px;
    }
}
.typePayor {
    .form-control.rounded-curve {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        background: none !important;
        background-color: #e8e8e7 !important;
    }
}

#user-search input[type='search']::-webkit-search-cancel-button {
    display: none;
}

.disable-muted {
    color: #b3b3b0;
    cursor: default;
}

.no-row-message-cont {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #6076a7;
}
.no-row-subtext-message-cont {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #6076a7;
}

.terminated-user-role-pills {
    border: 1px solid #b3b3b0 !important;
    background-color: #f0f0ef !important;
    color: #b3b3b0 !important;
    font-weight: 400;
}

.cursor-default {
    cursor: default !important;
}

// .h-40 {
//     height: 40px !important;
// }
// .scheduler-datepicker {
//     .datepicker-input-holder {
//         height: auto !important;
//     }
// }
.audit-log-box {
    background: var(--primary-blue-20, #f8f9fc);
    padding: 24px 16px;
}
.info-icon-color {
    .info-icon {
        color: $secondary !important;
    }
}

// colworx-ak styles
.secondryCard {
    border: 1px solid #b6bfd4;
    background-color: #b6bfd461;
    color: #1c3775;
    font-size: 20px;
    border-radius: 12px !important;
    height: 80px;
    font-weight: 500 !important;
}
.facilityCard {
    border: 1px solid #b6bfd4;
    background-color: #fff;
    padding: 3rem 1rem 3rem 2rem !important;
    font-size: 20px;
    margin-right: 3px;
    margin-bottom: 1rem;
    border-radius: 12px !important;
    height: 80px;
}
.status-pill {
    width: 88px;
    height: 24px;
    padding: 6px;
    font-weight: 500;
}
.border-warning-500 {
    border-color: #c28800 !important;
}
.text-warning-500 {
    color: #c28800 !important;
}
.ag-list-header-bg-blue {
    background: var(--primary-blue-40, #e2e7f3) !important;
}
#hideheaderAgGrid .ag-root .ag-header {
    display: none;
}

.drodown-show {
    opacity: 1;
    animation-name: bmd-dropdown-animation;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: 100% 0;
    right: 0;
    left: auto !important;
}

@keyframes bmd-dropdown-animation {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.whiteboard-availability-bar {
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    height: 11px;
    .bar {
        height: 100%;
    }
    &.unavailability {
        background-color: #ccd7f0;
    }
    &.blue {
        background-color: #ccd7f0;
        .time-block-bar {
            border: 2px solid $secondary-300;
            background-color: #ccd7f0 !important;
        }
        .bar {
            background-color: $secondary-300;
        }
    }
    &.yellow {
        background-color: #faedc5;
        .time-block-bar {
            border: 2px solid #e1aa00;
            background-color: #faedc5 !important;
        }
        .bar {
            background-color: #e1aa00;
        }
    }
    &.red {
        background-color: $primary-50;
        .time-block-bar {
            border: 2px solid $danger-100;
            background-color: $primary-50 !important;
        }
        .bar {
            background-color: $danger-100;
        }
    }
}

@media screen and (max-width: 768px) {
    .sticky-button {
        width: 100%;
        left: 0;
        right: 0;
        padding: 1rem;
        position: fixed;
        height: auto;
        min-height: 50px;
        background: #fff;
        bottom: 0;
    }
    .mobile-gap-bottom {
        margin-bottom: 8rem;
    }
    .dropdown {
        left: 10px !important;
        top: 10px !important;
    }
}
// colworx-ak styles end

// Responsive CSS
@media (min-width: 800px) and (max-width: 992px) {
    .md-screen {
        min-height: 460px;
    }
    .collapse:not(.show) {
        display: flex;
    }
    .navbar-expand-lg {
        .navbar-nav {
            flex-direction: row;
        }
    }
    .navbar {
        .container-fluid {
            flex-wrap: nowrap;
            a#site-logo-box {
                width: 20%;
                img {
                    width: 90% !important;
                }
            }
            .navbar-collapse {
                width: 80%;
                justify-content: end;
            }
        }
    }
    .navbar-nav {
        .dropdown-menu {
            position: absolute;
        }
    }
    #user-name-box {
        display: none;
    }
    .navbar-default {
        .sidebar-item {
            z-index: 700 !important;
        }
    }
    #dor-pending-doc {
        .dor-pending-doc-inner {
            margin-bottom: 15px;
        }
    }
    .steps-tab .step .title {
        font-size: 12px !important;
    }
    .therapy-admission-form-outer {
        padding: 10px 0 !important;
        #therapy_admission_form {
            padding: 0 !important;
        }
    }
    #patient-header-box {
        display: block !important;
    }
    .hide-mobile {
        display: none !important;
    }
    .show-mobile {
        display: flex !important;
        margin-bottom: 10px;
    }
    .mrn-patient {
        width: 100% !important;
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    #toggle-wrapper {
        width: 100%;
    }
    #search-and-button-box {
        display: flex;
    }
    .header-row .search-bar {
        width: auto !important;
    }
    .header-row .add-btn {
        width: auto !important;
        margin-left: 15px;
    }
    #gg-score-box:has(span) {
        margin-left: 0 !important;
    }
    #std-test-name:has(span) {
        margin-left: 0 !important;
    }
    #bar-card-wrapper {
        scroll-behavior: smooth;
        overflow-x: scroll !important;
    }
    #patient-search-wrapper {
        width: 195px !important;
    }
    .filter-label.body-medium-semibold {
        font-size: 12px !important;
    }
    #doc-list-wrapper,
    .planner-top-box,
    .grid-calendar {
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    .steps-tab .step .title {
        min-height: 42px;
    }
    .font-25 {
        font-size: 18px;
    }
    .responsive-font {
        font-size: 15px !important;
    }
    .date-picker-box {
        min-width: 191px;
    }
    .week-bar {
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    .label-left {
        width: 30px !important;
    }
    .week-label {
        min-width: 35px !important;
    }
    .frequency-inputs > input[type='number'],
    .frequency-inputs > .add-comment {
        width: 4.5vw !important;
        padding: 2px 4px !important;
    }
    .week-view-holder {
        width: 762px;
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    .scheduler-datepicker .chevron {
        width: 16px !important;
    }
    .small-scren-full-width {
        width: 100% !important;
    }
    .auto-saving-spinner {
        margin-right: 8em !important;
    }
    .skilled-services-menu {
        width: 100% !important;
    }
    .auto-saving-spinner {
        margin-right: 8em !important;
    }
    .skilled-services-menu {
        width: 100% !important;
    }
    .files-upload-modal {
        width: 800px;
        max-width: 800px;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    #dor-pending-doc {
        .dor-pending-doc-inner {
            display: block !important;
            min-height: 125px;
            .icon-wrapper {
                width: 44px !important;
                height: 44px !important;
            }
            .pending-doc-text {
                margin-left: 10px !important;
                font-size: 18px;
            }
            .count-box {
                width: 100% !important;
                justify-content: center;
                h5 {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .steps-tab .step .title {
        min-height: 42px;
    }
    .grid-calendar {
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    .week-view-holder {
        width: 762px;
        overflow: hidden;
        scroll-behavior: smooth;
        overflow-x: scroll;
    }
    .body-large,
    .body-large-bold,
    .body-large-semibold {
        font-size: 14px !important;
    }
}
.text-secondary-300 {
    color: $secondary-300;
}
.ml-14 {
    margin-left: 14px;
}
// adding margin to all printed docx so that the url and the page number is always visible
@media print {
    @page {
        margin-bottom: 3vh !important;
    }
}
#modal-facility-dropdown {
    label {
        font-size: 16px;
    }
} //colworx sk
@media only screen and (min-width: 350px) and (max-width: 500px) {
    .cdk-global-overlay-wrapper {
        justify-content: center;
        align-items: flex-end !important;
    }

    .modal-content.modal-medium {
        width: 100% !important;
    }
    .modal-content.modal-time {
        width: 100% !important;
    }
}
.preferences-checkbox {
    .checkbox-holder {
        label {
            margin-bottom: 0.3rem;
            font-weight: 600;
            margin-left: 20px;
            font-family: Inter;
            font-size: 13px;
        }
    }
}
.split-mint-box {
    border-radius: 4px;
    background-color: #f8f9fc;
    overflow: hidden;
    #split-mint-field {
        width: 50%;
        padding: 5px 10px;
        border: none !important;
        background-color: #f8f9fc;
        text-align: center;
        height: auto !important;
        color: #1c3775;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:focus {
            outline: none;
            border: none;
        }
    }
    span.min-box {
        width: 50%;
        text-align: center;
        padding: 5px 10px;
        border-left: 1px solid #e2e7f3;
    }
}
#split-mint-field::-webkit-outer-spin-button,
#split-mint-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#appointment-time.error {
    .ngb-tp {
        border: 1px solid var(--Error-400, #ac1616);
        .ngb-tp-meridian {
            button {
                color: var(--Error-400, #ac1616);
            }
        }
    }
    .ngb-tp-input-container {
        .form-control {
            color: var(--Error-400, #ac1616);
        }
    }
}
.therapist-available.form-control {
    color: var(--Primary-Blue-400, #1c3775);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.rounded-pill-bg {
    background: #e2e7f3 !important;
}
#user-audit-log-box {
    .user-audit-log-wrapper {
        overflow: visible !important;
    }
}
