.border-hover-primary {
  transition: border-color 0.2s linear;
}
.border-hover-primary:hover {
  border-color: $primary !important;
}
.border-primary {
  border: 1px solid $primary !important;
}
.border-secondary-300 {
  border: 1px solid$secondary-300 !important;
}
.border-secondary-50 {
  border: 1px solid$secondary-50 !important;
}
.input-border {
  border: 1px solid rgba(28, 55, 117, 0.15);
}

.border-transparent {
  border: 1px solid transparent !important;
}

@for $i from 1 through 20 {
  .border-#{$i} {
    border-width: #{$i}px !important;
  }
}

@for $i from 0 through 50 {
  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
  .border-top-left-radius-#{$i} {
    border-top-left-radius: #{$i}px !important;
  }
  .border-top-right-radius-#{$i} {
    border-top-right-radius: #{$i}px !important;
  }
  .border-bottom-left-radius-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
  }
  .border-bottom-right-radius-#{$i} {
    border-bottom-right-radius: #{$i}px !important;
  }
}
