@import './../functions/opacity-functions.scss';
@import './../variables.scss';
@for $opacity from 0 through 10 {
  .opacity-#{$opacity * 10} {
    opacity: calc($opacity / 10);
  }
}

// Generating opacity colors for the theme colors
@for $i from 1 through 10 {
  @each $color-name, $color-value in $color-maps-50 {
    $alpha-value: calc($i * 10);
    // creating classes for foreground colors like text color etc
    .fg-#{$color-name}-a#{$alpha-value} {
      color: generateColorAlpha($color-value, $alpha-value) !important;
    }
    // creating classes for background colors like text color etc
    .bg-#{$color-name}-a#{$alpha-value} {
      background-color: generateColorAlpha($color-value, $alpha-value) !important;
    }
  }
}
@for $i from 1 through 10 {
  @each $color-name, $color-value in $color-maps-100 {
    $alpha-value: calc($i * 10);
    // creating classes for foreground colors like text color etc
    .fg-#{$color-name}-a#{$alpha-value} {
      color: generateColorAlpha($color-value, $alpha-value) !important;
    }
    // creating classes for background colors like text color etc
    .bg-#{$color-name}-a#{$alpha-value} {
      background-color: generateColorAlpha($color-value, $alpha-value) !important;
    }
  }
}
@for $i from 1 through 10 {
  @each $color-name, $color-value in $color-maps-200 {
    $alpha-value: calc($i * 10);
    // creating classes for foreground colors like text color etc
    .fg-#{$color-name}-a#{$alpha-value} {
      color: generateColorAlpha($color-value, $alpha-value) !important;
    }
    // creating classes for background colors like text color etc
    .bg-#{$color-name}-a#{$alpha-value} {
      background-color: generateColorAlpha($color-value, $alpha-value) !important;
    }
  }
}
@for $i from 1 through 10 {
  @each $color-name, $color-value in $color-maps {
    $alpha-value: calc($i * 10);
    // creating classes for foreground colors like text color etc
    .fg-#{$color-name}-a#{$alpha-value} {
      color: generateColorAlpha($color-value, $alpha-value) !important;
    }
    // creating classes for background colors like text color etc
    .bg-#{$color-name}-a#{$alpha-value} {
      background-color: generateColorAlpha($color-value, $alpha-value) !important;
    }
  }
}
@for $i from 1 through 10 {
  @each $color-name, $color-value in $color-maps-400 {
    $alpha-value: calc($i * 10);
    // creating classes for foreground colors like text color etc
    .fg-#{$color-name}-a#{$alpha-value} {
      color: generateColorAlpha($color-value, $alpha-value) !important;
    }
    // creating classes for background colors like text color etc
    .bg-#{$color-name}-a#{$alpha-value} {
      background-color: generateColorAlpha($color-value, $alpha-value) !important;
    }
  }
}
