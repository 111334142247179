// Style for planner single day is in planner-single-day.scss file
// New styles
.planner-item-border {
  border: 6px solid transparent !important;
}

// Old styles

.planner-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.planner-timings-container {
  max-height: 80vh;
  overflow: auto;
}

table.days-table {
  // border-collapse: separate;
  border-radius: 15px;
  // border-spacing: 0;
  th {
    background-color: $primary;
    color: white;
    width: 70px;
    overflow: hidden;
    text-align: center;
  }
  td {
    text-align: center;
  }
  th:first-child {
    border-radius: 8px 0px 0px 0px;
  }
  th:last-child {
    border-radius: 0px 8px 0px 0px;
  }
}

.planner-search-container {
  height: 100vh !important;
  max-height: 68vh !important;
  background-color: white;
  width: 100%;
  .results {
    height: calc(100vh - 300px);
  }
  .patient-info-card {
    overflow: auto !important;
  }
}

// Working with planner grid layout
.grid-calendar {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(7, auto);
}

// height adjustments

.vh-120 {
  height: 120vh !important;
}

// Single plan common in both week and month views
.single-plan {
  &:hover {
    @extend .shadow-lg;
  }
}

// week view calendar of planner

.planner {
  &-wv {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 87% 10%;
    grid-gap: 8px;
    &-inner-layout {
      display: grid;
      grid-template-columns: 1fr repeat(7, 5fr);
      overflow: hidden;
      height: 98vh !important;
      grid-gap: 8px;
    }
    &-day-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
    &-day {
      // height: 290px !important;
      // height: 250px !important;
      height: 200px !important;
      // overflow: hidden;
    }
    &-footer {
      display: grid;
      grid-gap: 8px;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr repeat(7, 5fr);
      &-item {
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: $white;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// Month View

// Planner month view
.mv {
  &-day {
    &-head {
      // Month view day head
      display: grid;
      grid-template-columns: 3fr repeat(3, 1fr);
    }
    &-body {
      // Month view day body
      display: grid;
      grid-template-columns: 3fr repeat(3, 1fr);
    }
  }
}
@media screen and (min-width: 1600px) {
  .planner {
      &-wv {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 87% 10%;
        grid-gap: 8px;
  
        &-inner-layout {
          display: grid;
          grid-template-columns: 1fr repeat(7, 5fr);
          overflow: hidden;
          height: 76vh !important;
          grid-gap: 8px;
        }
  
        &-day-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(3, 1fr);
        }
  
        &-day {
          // height: 290px !important;
          // height: 250px !important;
          height: 200px !important;
          // overflow: hidden;
        }
  
        &-footer {
          display: grid;
          grid-gap: 8px;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr repeat(7, 5fr);
  
          &-item {
            padding-left: 0.5em;
            padding-right: 0.5em;
            padding-top: 1em;
            padding-bottom: 1em;
            background-color: $white;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .planner-search-container {
      max-height: 75vh !important;}
}
