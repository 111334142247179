/**
This utility adds some legacy style classes from bootstrap 4 and some extra utilities as well.
**/

// Bootstrap mr, ml, mt, mb utility

@for $i from 1 through 5 {
    .mr-#{$i}-before {
        &::before {
            @extend .mr-#{$i};
        }
    }
    .ml-#{$i}-before {
        &::before {
            @extend .ml-#{$i};
        }
    }
    .mt-#{$i}-before {
        &::before {
            @extend .mt-#{$i};
        }
    }
    .mb-#{$i}-before {
        &::before {
            @extend .mb-#{$i};
        }
    }
}

// custom w-* utility for w-{N}em
@for $i from 1 through 30 {
    $point-value: $i + 0.5;
    .w-#{$i}em {
        width: #{$i}em !important;
    }
    .w-#{$i}-5em {
        width: #{$point-value}em !important;
    }
    .w-#{$i}rem {
        width: #{$i}rem !important;
    }
    .w-#{$i}-5rem {
        width: #{$point-value}rem !important;
    }
}
// custom h-* utility for h-{N}em
@for $i from 1 through 30 {
    $point-value: $i + 0.5;
    .h-#{$i}em {
        height: #{$i}em !important;
    }
    .h-#{$i}-5em {
        height: #{$point-value}em !important;
    }
    .h-#{$i}rem {
        height: #{$i}rem !important;
    }
    .h-#{$i}-5rem {
        height: #{$point-value}rem !important;
    }
}
// custom max-w-* utility for max-w-{N}em
@for $i from 1 through 30 {
    $point-value: $i + 0.5;
    .max-w-#{$i}em {
        max-width: #{$i}em !important;
    }
    .max-w-#{$i}-5em {
        max-width: #{$point-value}em !important;
    }
    .max-w-#{$i}rem {
        max-width: #{$i}rem !important;
    }
    .max-w-#{$i}-5rem {
        max-width: #{$point-value}rem !important;
    }
}
// custom max-h-* utility for max-h-{N}em
@for $i from 1 through 30 {
    $point-value: $i + 0.5;
    .max-h-#{$i}em {
        max-height: #{$i}em !important;
    }
    .max-h-#{$i}-5em {
        max-height: #{$point-value}em !important;
    }
    .max-h-#{$i}rem {
        max-height: #{$i}rem !important;
    }
    .max-h-#{$i}-5rem {
        max-height: #{$point-value}rem !important;
    }
}
