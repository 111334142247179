@import './icons/style.scss';
.badge-icon {
  position: relative;
  .top-right-badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
  }
}
