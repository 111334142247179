.steps-tab {
  display: flex;
  .step {
    cursor: pointer;
    width: 210px;
    .title {
      @extend .body-medium-semibold;
    }
    .path {
      position: relative;
      .current {
        width: 100%;
        height: 3px;
        background: $primary;
        .circle-end {
          background-color: $primary;
          position: absolute;
          right: 0;
          width: 18px;
          height: 18px;
          .done-icon {
            font-size: 15px;
            font-weight: bolder;
          }
        }
      }
      .not-done {
        width: 100%;
        height: 3px;
        background: $gray-100;
        .circle-end {
          background-color: $gray-100;
          position: absolute;
          right: 0;
          width: 18px;
          height: 18px;
          .done-icon {
            font-size: 15px;
            font-weight: bolder;
          }
        }
      }
    }
  }
}
// colworx-ak: reduce width of steper for responsive?
@media screen and (max-width: 768px) {
  .steps-tab .step{
    width: 140px;
  }
}


