.date-renderer {
  border: none;
  outline: none;
  background: transparent;
  // &::-webkit-inner-spin-button,
  // &::-webkit-calendar-picker-indicator {
  //   display: none;
  //   -webkit-appearance: none;
  // }
  &::-webkit-datetime-edit-fields-wrapper {
    text-decoration: underline;
  }
}

#cpt-search-container {
  .ag-header {
    display: none !important;
  }
}

.auto-height-grid {
  .ag-center-cols-clipper {
    min-height: 50px !important;
  }
}
