.alert-container {
  position: fixed;
  top: 10px;
  right: 50%;
  z-index: 9999;
}

.alert-common {
  color: $white !important;
  border-radius: 10px !important;
}

.innova-alert-danger {
  @extend .alert-common;
  @extend .alert;
  @extend .alert-danger;
  background-color: $danger !important;
}
.innova-alert-warning {
  @extend .alert-common;
  @extend .alert;
  @extend .alert-warning;
  background-color: $warning !important;
}
.innova-alert-success {
  @extend .alert-common;
  @extend .alert;
  @extend .alert-success;
  background-color: $success !important;
}
.innova-alert-info {
  @extend .alert-common;
  @extend .alert;
  @extend .alert-info;
  background-color: $info !important;
}
