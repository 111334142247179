.sidebar-icon-size {
  font-size: 1.5em;
}

.innova-navbar {
  height: 100% !important;
  position: relative !important;
  transition: width 0.5s ease-in-out !important;
  // z-index: $zindex-offcanvas !important;
  .brand {
    transition: background-size 0.5s ease-in-out !important;
    background-color: $white !important;
    &-collapsed {
      height: $header-height !important;
      width: $sidebar-width;
      background-size: 60% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-color: $white !important;
    }
    &-expanded {
      height: $header-height !important;
      width: $expanded-sidebar-width;
      background: url(../logo/logo.svg) !important;
      background-size: 80% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-color: $white !important;
    }
  }
}

.hidden {
  margin-left: -6.8em !important;
}
.visible {
  margin-left: 0px !important;
}

.navbar-default {
  position: relative;
  height: calc(100% - $header-height);
  width: $sidebar-width;
  border-right: $nav-right-border;
  justify-content: space-between;
  background-color: $secondary !important;
  // z-index: 99999;
  // overflow-x: hidden !important;
  // Sidebar item
  .active {
    .sidebar-item-inner {
      .icon-circle-nav {
        background-color: $primary !important;
        color: $white !important;
      }
      .text {
        color: $primary !important;
      }
    }
  }
  .sidebar-item {
    .sidebar-dropdown {
      position: absolute !important;
      top: -30px !important;
      left: 40% !important;
      z-index: 1050 !important
    }
    @extend .icon-text-item;
    transition: background-color 0.3s ease-in-out;
    padding: 2.4rem !important;
    width: 100%;
    height: 64px;
    $background-color: transparent !important;
    cursor: pointer;
    &:hover {
      background-color: $white !important;
      .sidebar-item-inner {
        .icon-circle-nav {
          background-color: $primary !important;
          color: $white !important;
        }
        .text {
          color: $primary !important;
        }
      }
    }

    :nth-child(1) {
      margin-top: 0px;
    }
    .sidebar-item-inner {
      @extend .sidebar-item;
      background-color: transparent !important;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 8px;
      padding-bottom: 8px;
      // margin-top: 0px;
      .icon-circle-nav {
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        background-color: $white;
        color: $secondary;
      }
      .text {
        transition: color 0.3s ease-in-out;
        color: $white;
        font-family: poppins;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.sidebar-toggle {
  // collapsed sidebar toggle
  @extend .rounded-circle;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center !important;
  position: absolute;
  z-index: 9999 !important;
  top: 6em;
  right: -0.6em;
  width: 25px;
  height: 25px;
  background-color: $white !important;
  &:hover {
    color: $white;
    background-color: $primary !important;
  }
}

// Sidenav
.right-bar {
  padding: 24px;
  background-color: white;
  height: 100vh;
  width: 380px;
  box-shadow: -13px 0px 34px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 1600px) {
  .navbar-default{
    .sidebar-item {
      .sidebar-dropdown {
        top: 0 !important;      
      }
      padding: 2.1rem 2.4rem !important;
    }
  }
}