.innova-table-v2 {
  td,
  th {
    padding: 1em;
  }
  th {
    @extend .text-white;
    @extend .bg-secondary;
    @extend .border-radius-0;
  }
  tr {
    td {
      border: none;
      border-bottom: 1px solid $body-background;
      border-top: 1px solid $body-background;
    }
    @extend.border-radius-0;
    border: 1px solid $body-background;
    td:first-child {
      border: none;
      border-left: 1px solid $body-background;
      border-bottom: 1px solid $body-background;
      border-top: 1px solid $body-background;
    }
    td: last-child {
      border: none;
      border-right: 1px solid $body-background;
      border-bottom: 1px solid $body-background;
      border-top: 1px solid $body-background;
    }
  }
  tr:nth-child(even) {
    @extend .bg-body-background;
  }
  .form-group {
    margin: 0px !important;
    padding: 0px !important;
  }
}
