.auth {
  .card {
    background: #ffffff;
    padding: 90px;
    width: 500px;
    border-radius: 14px;
    border: none !important;
    outline: none !important;
  }
  .form-control {
    @extend .bg-body-background;
  }
  height: calc(100vh - $header-height);
  .logo {
    width: 120%;
  }
}
