.scheduler-filter-button {
  button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    background-color: $disabled;
    border: none;
    color: $primary;
  }
}

.scheduler {
  &-height {
    height: $scheduler-height;
  }
  &-wv {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100% 10%;
    grid-gap: 8px;
    height: calc(100vh  - 170px);
    &-inner-layout {
      display: table;
      table-layout: fixed;
      // grid-template-columns: repeat(7, 1fr);
      overflow: hidden;
      // height: $scheduler-height !important;
      // grid-gap: 8px;
      // height: 100%;
    }
    &-day-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
    &-day {
      // height: 290px !important;
      // height: 250px !important;
      height: 200px !important;
      // overflow: hidden;
    }
  }
}
