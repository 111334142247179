@import './utilities/style.scss';

.cursor {
  &-pointer {
    cursor: pointer !important;
  }
  &-grab {
    cursor: grab !important;
  }
  &grabbing {
    cursor: grabbing !important;
  }
}

body {
  background-color: $body-background !important;
}

.unselectable {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
  }
}

// hover animations
.hover {
  &:hover {
    background-color: rgba($primary-light, 0.1);
  }
}

// heights and widths

.max-80vh {
  max-height: 80vh !important;
}

.absolute-top-0 {
  top: 0;
}
.absolute-right-0 {
  right: 0;
}
.absolute-left-0 {
  left: 0;
}
.absolute-bottom-0 {
  bottom: 0;
}

// z-index

.zindex-dropdown {
  z-index: $zindex-dropdown !important;
}
.zindex-sticky {
  z-index: $zindex-sticky !important;
}
.zindex-sticky {
  z-index: $zindex-sticky !important;
}
.zindex-fixed {
  z-index: $zindex-fixed !important;
}
.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop !important;
}
.zindex-offcanvas {
  z-index: $zindex-offcanvas !important;
}
.zindex-modal {
  z-index: $zindex-modal !important;
}
.zindex-popover {
  z-index: $zindex-popover !important;
}
.zindex-tooltip {
  z-index: $zindex-tooltip !important;
}

// Paddings
.p-notification-badge {
  padding: 0.15rem !important;
}

// Inputs

.input-height {
  height: $input-height !important;
}

.input-border-radius {
  border-radius: $input-border-radius;
}

// Text utitlities

.text-underline {
  text-decoration: underline !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

// Spacing

.whitespace-nowrap {
  white-space: nowrap !important;
}

// Visibility

.visibility-hidden {
  visibility: hidden !important;
}
.visibility-visible {
  visibility: visible !important;
}
