// shadows
.shadow-main-sheet {
  box-shadow: $shadow-main-sheet !important;
}

.input-shadow {
  box-shadow: $input-shadow !important;
}
.nav-shadow {
  box-shadow: $nav-shadow !important;
}

.form-shadow {
  box-shadow: $form-shadow !important;
}

.search-shadow {
  box-shadow: $search-shadow !important;
}

.dropdown-shadow {
  box-shadow: $dropdown-shadow !important;
}
