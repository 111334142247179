.innova-table {
  @extend .table;
  thead {
    background-color: $light-grey;
    border: none !important;
    border-collapse: separate;
  }
  tbody {
    border: none !important;
  }
  tr {
    border: none !important;
  }
  th {
    border: none !important;
    // text-align: center;
    vertical-align: middle !important;
  }
  td {
    @extend .caption;
    border: none !important;
    padding: 10px;
    vertical-align: middle !important;
  }
  th:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  th:last-child {
    border-radius: 0px 8px 8px 0px;
  }
  .table-icon {
    width: 14px;
    height: 18px;
    cursor: pointer;
  }
  tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.table-add-button {
  padding: 9px 22.5px 9px 22.5px !important;
}

// .table-scroll {
//   width: 100%;
//   tbody {
//     display: block;
//     height: 85vh;
//     overflow-y: scroll;
//   }
//   thead {
//     display: block;
//     width: 100%;
//   }
// }

// delable

.scrollable-table {
  width: 100%;
  tbody {
    display: block;
    overflow-y: scroll;
  }
  thead,
  tbody tr {
    display: table;
    width: 100% !important;
    table-layout: fixed;
  }
}

.modal-table-scroll {
  @extend .scrollable-table;
  tbody {
    max-height: 60vh;
  }
}

tr.rounded-row {
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
  }
}
