@import './variables.scss';
.layout-transition {
    transition: width 0.5s ease-in-out !important;
}
.app-container {
    display: grid;
    grid-row: 1;
    // grid-template-columns: $sidebar-width 12fr;

    // &-expanded {
    //   grid-template-columns: $expanded-sidebar-width 12fr;
    // }
}

.body-grid-layout {
    display: grid;
    grid-template-columns: 0fr 10fr;
    grid-template-rows: calc(100vh - $header-height);
    // height: 100%;
    // grid-template-rows: $header-height calc(100% - $header-height);
    // .body-expanded {
    //   width: 100%;
    // }
    // .body-collapsed {
    //   width: 100%;
    // }
    // grid-template-columns: calc(100% - $sidebar-width);
    // margin-top: $header-height !important;
}
.sub-navbar {
    background-color: $secondary-50 !important;
}
.sub-navbar button:hover {
    background-color: $primary-200 !important;
    color: $white !important;
}
.single-col {
    display: grid;
    grid-row: 2;
    grid-template-columns: 12fr !important;
    margin-top: $header-height !important;
}

.sticky-top {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0 !important;
    width: 100% !important;
}
.sticky-sidebar {
    position: sticky;
    top: 0;
}

.app-header-container {
    // max-height: 10vh;
    height: $header-height;
    // z-index: $zindex-app-header;
}

.custom-navbar {
    height: $header-height;
}

.app-body-container {
    height: calc(100% - $header-height);
    position: relative;
}
// .patients-left-card {
//   -ms-flex: 0 0 281px !important;
//   flex: 0 0 281px !important;
// }
.pcc-header-layout {
    padding-left: 19px;
    padding-top: 15px;
}
.pcc-table {
    width: 100%;
    th,
    td {
        padding: 5px 10px 10px;
        text-align: left;
    }
}
.back-button {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 34px !important;
    font-weight: 600;
    cursor: pointer;
}

.button-skip {
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    border-radius: 100px !important;
    height: 61px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    background-color: $body-background !important;
    color: $secondary-200 !important;
    border-color: $secondary-200 !important;
    &:hover {
        background-color: $secondary-300 !important;
        color: $body-background !important;
        border-color: $body-background !important;
    }
}
.login-image {
    background-repeat: no-repeat;
    background-size: cover;
}
.loading-animation-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgb(255, 255, 255);
    display: grid;
    align-items: center;
    justify-items: center;
}

.loading-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    width: 150px;
    height: auto;
}
.scheduler-container {
    height: calc(100vh - $header-height - 25px);
    overflow: hidden;
}
.scheduler-main {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.scheduler-main::-webkit-scrollbar {
    display: none;
}

.ag-cell-focus,
.ag-cell-no-focus {
    border: none !important;
}
.ag-cell:focus {
    border: none !important;
    outline: none;
}
.ag-cell.disabled-checkbox .ag-selection-checkbox {
    pointer-events: none;
    opacity: 0.5;
  }
.col-xs-0,
.col-sm-0,
.col-md-0,
.col-lg-0 {
    flex: 0 0 0;
    max-width: 0;
    padding-right: 0;
    padding-left: 0;
}
.ag-root.ag-unselectable.ag-layout-normal {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
// Comment the code to add scrollbar to the bottom of the data grid
// .ag-root.ag-unselectable.ag-layout-normal ::-webkit-scrollbar {
//     display: none;
// }
.cdk-overlay-backdrop {
    position: relative !important;
    width: 100%;
    height: 100%;
    opacity: 0.46 !important;
    background: black;
}

@media screen {
    .content {
        overflow: auto !important;
        overflow-x: hidden !important;
    }
}
