@import './../functions/opacity-functions.scss';
.card {
  @extend .card;
  background-color: $white !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.card-border {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid generateColorAlpha($secondary, 40);
  border-radius: 8px;
}

.innova-card {
  background: $white;
  border: 1px solid rgba(165, 181, 217, 0.4);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
