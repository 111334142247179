// Steps container
.steps-container {
  margin-left: 17px;
  .nav-pills {
    a.nav-link {
      background-color: $surface-background;
      color: $primary;
      border-radius: 0px;
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-top: 20px;
      padding-left: 23px;
      padding-right: 23px;
      padding-top: 16px;
      padding-bottom: 16px;
      :nth-child(1) {
        margin-top: 0px;
      }
    }
    a.active {
      // This section is subjected to change that is why left as it is
      background-color: #c5dcfa !important;
      color: $primary;
      border-radius: 0px;
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

// Payor information style
.payor-list-title {
  background: $light-grey;
  border-radius: 8px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 13px 11px 13px 11px;
}

.payor-container {
  min-width: 160px;
}

ul.select-payor-list {
  padding: 0px;
  li {
    list-style-type: none;
    padding: 13px 11px 13px 11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 26, 55, 0.6);
    cursor: pointer;
    &:hover {
      background-color: $hover;
    }
  }
}
