.d-grid {
  display: grid;
}

@for $fractions from 1 through 12 {
  @for $cr from 1 through 50 {
    .grid-template-columns-#{$cr}-#{$fractions}fr {
      grid-template-columns: repeat($cr, #{$fractions}fr);
    }
    .grid-auto-columns-#{$cr}-#{$fractions}fr {
      grid-auto-columns: repeat($cr, #{$fractions}fr);
    }
    .grid-template-rows-#{$cr}-#{$fractions}fr {
      grid-template-rows: repeat($cr, #{$fractions}fr);
    }
    .grid-auto-rows-#{$cr}-#{$fractions}fr {
      grid-auto-rows: repeat($cr, #{$fractions}fr);
    }
  }
}
