@import 'src/assets/styles/variables.scss';
// General Form
.form-height {
  // max-height: 503px;
  height: $dynamic-form-height !important;
}
.innova-form {
  .form-footer {
    @extend .border-bt-rounded;
    max-height: $form-footer-height;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // border-radius: 0px 0px 10px 10px;
  }
  .form-group {
    label {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.15px;
      color: $secondary-300;

    }
  }
}

// Form for current step
.steps-form-container {
  @extend .innova-form;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  display: grid;
  grid-template-columns: 12fr 2fr;
  .one {
    grid-column: 1/3;
  }
  .two {
    grid-column: 2;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
}

.form-group {
  label {
    font-family: Poppins;
    color:  $secondary-300;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-left: 6px;
    min-width: 70px;
  }
}
