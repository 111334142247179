// New Design

// Old styles remove if not required
.search-patients {
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
  }
}
.search-fixed-width {
  width: 350px !important;
}
