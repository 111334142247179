@import './animations/rotate.scss';
.card-shrink-animation {
    animation: card-shrink-animation 0.3s ease-in-out;
}

@keyframes card-shrink-animation {
    0% {
        width: 292px;
        height: 150px;
    }
    100% {
        width: 204px;
        height: 108px;
    }
}
.card-expand-animation {
    animation: card-expand-animation 0.3s ease-in-out;
}

@keyframes card-expand-animation {
    0% {
        width: 204px;
    }
    100% {
        width: 97%;
        height: 100%;
    }
}
