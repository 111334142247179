$icomoon-font-family: 'innovaicon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-add: '\e900';
$icon-billing: '\e901';
$icon-dashboard: '\e902';
$icon-first-aid: '\e903';
$icon-menu: '\e904';
$icon-notepad: '\e905';
$icon-time: '\e906';
$icon-user: '\e907';
