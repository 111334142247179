/* You can add global styles to this file, and also import other style files */

// Required
@import '~bootstrap/scss/functions';

// Required
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import 'variables.scss';

$theme-colors: (merge-n-maps($color-maps-50, $color-maps-100, $color-maps-200, $color-maps, $color-maps-400));

$utilities: (
  'color': (
    property: color,
    class: text,
    values: $theme-colors,
  ),
  'bg': (
    property: background-color,
    class: bg,
    values: $theme-colors,
  ),
);

// Optional Bootstrap components here
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// etc

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
