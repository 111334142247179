// Custom Models

.modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .custom-modal {
    @extend .rounded-modal;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    // box-shadow: 0px 1px 6px   rgba(0, 0, 0, 0.4);
    // max-width: 733px !important;
    // max-height: 520px !important;
    // width: 80vw;
    .modal-content {
      // height: calc(100vh - $header-height);
      margin-top: $header-height;
      border: none !important;
      .modal-body {
        // height: calc(100vh - 215px);
        overflow-y: hidden;
      }
      .modal-header {
        border: none;
        padding: 0px;
        .modal-title {
          @extend .p-2;
        }
      }
      .modal-footer {
        border: none;
      }
    }
  }
}

// Modal components

.modal-close-button {
  border-radius: 0px 12px 0px 0px !important;
}
