@import 'src/assets/styles/variables.scss';
ngb-datepicker {
  position: absolute;
  background-color: white;
  border-radius: 8px !important;
  padding: 10px !important;
  box-shadow: 2px 16px 19px rgba(0, 0, 0, 0.09);
  .ngb-dp-header {
    background-color: transparent;

    ngb-datepicker-navigation {
      .ngb-dp-arrow {
        button {
          color: #b3b3b3;
          font-size: 12px;
        }
      }
      .ngb-dp-month-name {
        background-color: transparent;
        font-size: medium;
        font-weight: 600;
        color: #333333;
      }
      ngb-datepicker-navigation-select > .custom-select {
        color: $secondary-300;
        font-family: Poppins;
      }
    }
  }

  .ngb-dp-content {
    &.ngb-dp-months {
      ngb-datepicker-month {
        .ngb-dp-weekdays {
          background: none;
          border-bottom: none;
          border-radius: none;
          padding-top: 10px;

          .ngb-dp-weekday {
            color: #b3b3b3;
            font-style: normal;
          }
        }
      }
    }
  }
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
  color: $secondary-300;
  font-weight: 600;
  font-size: 14px;
}
.custom-day:hover,
.custom-day.focused {
  background-color: $primary-300;
  border-radius: 20px;
  color: white;
}

.selected-day {
  background-color: $primary-300 !important;
  border-radius: 20px;
  color: white;
}
.hidden {
  display: none;
  visibility: hidden;
}
