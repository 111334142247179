h1,
.h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 55px !important;
    line-height: 82px !important;
    &-semibold {
        @extend .h1;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .h1;
        font-weight: 700 !important;
    }
}

h2,
.h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 50px !important;
    line-height: 75px !important;
    &-semibold {
        @extend .h2;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .h2;
        font-weight: 700 !important;
    }
}

h3,
.h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 45px !important;
    line-height: 68px !important;
    &-semibold {
        @extend .h3;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .h3;
        font-weight: 700 !important;
    }
}

h4,
.h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 40px !important;
    line-height: 60px !important;
    &-semibold {
        @extend .h4;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .h4;
        font-weight: 700 !important;
    }
}

h5,
.h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 25px !important;
    line-height: 33px !important;
    &-semibold {
        @extend .h5;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .h5;
        font-weight: 700 !important;
    }
}

h6,
.h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    &-semibold {
        @extend .h6;
        font-weight: 600 !important;
        line-height: 30px !important;
    }
    &-bold {
        @extend .h6;
        font-weight: 700 !important;
        line-height: 30px !important;
    }
}

.body-large {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    &-semibold {
        @extend .body-large;
        font-weight: 600 !important;
        line-height: 24px !important;
    }
    &-bold {
        @extend .body-large;
        font-weight: 700 !important;
        line-height: 24px !important;
    }
}

.body-medium {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    &-semibold {
        @extend .body-medium;
        font-weight: 600 !important;
    }
    &-bold {
        @extend .body-medium;
        font-weight: 700 !important;
    }
}

.body-small {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    &-semibold {
        @extend .body-small;
        font-weight: 600 !important;
        // line-height: 13px !important;
    }
    &-bold {
        @extend .body-small;
        font-weight: 700 !important;
        // line-height: 13px !important;
    }
}

//old fonts here
.subtitle1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.subtitle2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}

.body1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}

.body2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.body3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}

.button-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.caption {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 14px;
    color: $black;
}

.overline-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
}

.body-custom {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

// font sizes
.font-16 {
    font-size: 16px !important;
}
.font-18 {
    font-size: 18px !important;
}
.font-20 {
    font-size: 20px !important;
}
// colworx-ak:
.font-25 {
    font-size: 25px !important;
}
.font-39 {
    font-size: 39px !important;
}
//
.w-90{
    width: 90% !important;
}
.w-10{
    width: 10% !important;
}
// Scheduler fonts
.scheduler-row-cell-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.15px;
}

.context-menu-option-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.pcc-table-header-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
}
.calendar-Btn {
    max-width: 40px;
    cursor: pointer;
}

.small-numbers {
    /* quicksand_number_small */

    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
}

// font-weights
.fw-100 {
    font-weight: 100;
}
.fw-200 {
    font-weight: 200;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600  !important;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}
.line-space {
    white-space: normal !important;
    padding: 10px !important;
}
.total-scheduled-time {
    color: var(--secondary-one-300, #1c3775);

    /* Body Font 14px Bold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.chart-notes {
    color: var(--secondary-one-300, #1c3775);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px; /* 108.333% */
}
.font-pop-700-16 {
    /* Body Font 16px Bold */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.font-pop-700-14 {
    /* Body Font 16px Bold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.font-pop-600-14 {
    /* Body Font 14px Samibold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.font-pop-400-12 {
    /* Body Font 14px Samibold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.font-pop-400-12 {
    /* Body Font 14px Samibold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
