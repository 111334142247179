// Overlay

.overlay {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @for $i from 1 through 10 {
    &-disabled-#{$i*10} {
      @extend .overlay;
      &::after {
        background-color: rgba($disabled, $i/10);
      }
    }
    &-white-#{$i*10} {
      @extend .overlay;
      &::after {
        background-color: rgba($white, $i/10);
      }
    }
  }
}
